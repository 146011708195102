import { Component, Input, inject } from '@angular/core';
import { Router } from '@angular/router';
import { MembershipTransaction } from 'src/app/shared/models/transactions/membership-transaction.model';
import { SaleTransaction } from 'src/app/shared/models/transactions/sale-transaction.model';
import { APP_CONFIG } from 'src/configuration/configuration';

@Component({
  selector: 'app-summary-success',
  templateUrl: './summary-success.component.html',
  styleUrl: './summary-success.component.css'
})
export class SummarySuccessComponent {

  @Input()
  transactionId!:   number;

  @Input()
  saleTransaction!: SaleTransaction | MembershipTransaction;

  @Input()
  type!:  	        string;

  private router:     Router = inject(Router);
  protected currency: string = inject(APP_CONFIG).general.currency;

  public returnToHome(): void {
    this.router.navigate(['']);
  }
  
}
