<div class="flex flex-col h-full overflow-hidden" *ngIf="saleTransaction">
    <section>

        <div class="container mx-auto py-5">
            <h2 class="font-semibold text-secondary text-base text-center">
                Your transaction was completed successfully!
            </h2>
            <p class="text-darkgray text-sm text-center mb-2">
                Here are the details of your transaction:
            </p>
            
            <!-- Details -->
            <div class="relative h-8 mx-auto block">
                <div class="absolute shadow-md top-2 bg-white rounded-lg w-3/4 flex flex-col lg:flex-row p-5 gap-y-3 justify-between left-[12%]">
                    <p class="text-gray-700 flex justify-between items-center">
                       Transaction ID:  
                        <span class="font-main font-semibold text-gray-900 block ml-1 text-sm">{{saleTransaction.id}}</span>
                    </p>
                    <p class="text-gray-700 flex justify-between items-center">
                        Delivery Method:  
                        <span class="font-main font-semibold text-gray-900 block ml-1 text-sm">{{saleTransaction.delivery_method_description}}</span>
                    </p>
                    <p class="text-gray-700 flex justify-between items-center">
                         Total Price: 
                        <span class="font-main font-semibold text-gray-900 block ml-1 text-sm">{{saleTransaction.total_price | currency:currency}}</span>
                    </p>
                </div>
            </div>
    
        </div>
    
    </section>
    <section class="bg-white flex flex-col items-center flex-grow lg:overflow-y-auto">

        <div class="mt-28 md:mt-10 lg:mt-8 container mx-auto">
            <p class="my-3 text-center text-darkgray">
                Your order contains the following items:
            </p>
            <div *ngIf="saleTransaction" class="flex flex-col items-center mx-auto p-5 lg:w-1/2">
                <app-checkout-ticket [saleData]="saleTransaction" [type]="type"/>
            </div>
        </div>

        <button class="button-secondary my-5 font-medium w-5/12 lg:w-3/12" (click)="returnToHome()">
            Buy More Tickets
        </button>

    </section>
</div>