<div class="bg-white w-full rounded-md flex flex-col p-3">
    
    <!-- Customer -->
   <div class="flex justify-between items-center font-medium" (click)="toggleVisibility()">
    <p class="text-secondary">
        {{customer.name}}
    </p>
    <i [ngClass]="{'rotate-180': isOpen}" class="transition-transform duration-200 ease-in cursor-pointer">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-secondary dark:text-white">
            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    </i>
   </div>

   <!-- Buyer Type Info -->
   <ng-container *ngIf="isOpen">

        <!-- Separador -->
        <div class="mt-3">
            <hr>
        </div>

        <!-- SALE TICKETS Buyer -->
        <div *ngIf="saleBuyerType">
            <div *ngFor="let buyerType of saleBuyerType">
                <div class="mt-3 flex justify-between font-medium text-gray-800/85 text-sm">
                    <p>
                        {{buyerType.buyer_type_name}}
                    </p>
                    <p>
                        Seats {{buyerType.seats.length}}
                    </p>
                </div>
                <div *ngFor="let seat of buyerType.seats" class="flex justify-between mt-1.5">
                    <p class="text-darkgray text-xs">
                        Section:
                        <span class="text-secondary text-sm font-medium">
                            {{seat.tdc_section}}
                        </span>
                    </p>
                    <p class="text-darkgray text-xs">
                        Row:
                        <span class="text-secondary text-sm font-medium">
                            {{seat.tdc_seat_row}}
                        </span>
                    </p>
                    <p class="text-darkgray text-xs">
                        Seat:
                        <span class="text-secondary text-sm font-medium">
                            {{seat.tdc_seat_number}}
                        </span>
                    </p>
                </div>
            </div>
        </div>

        <!-- RESERVATION SALE Buyer -->
        <ng-container *ngIf="reservationBuyerType">
            <ng-container *ngFor="let orders of reservationBuyerType.orders_claimed">
                <div *ngFor="let items of orders.pending_items" class="mt-3 flex flex-col justify-between font-medium text-gray-800/85 text-sm">
                    <span>
                        {{items.name}}
                    </span>
                    <div *ngFor="let seat of items.seats" class="flex justify-between mt-1.5 lg:w-10/12">
                        <p class="text-darkgray text-xs">
                            Section:
                            <span class="text-secondary text-sm font-medium">
                                {{seat.tdc_section}}
                            </span>
                        </p>
                        <p class="text-darkgray text-xs">
                            Row:
                            <span class="text-secondary text-sm font-medium">
                                {{seat.tdc_seat_row}}
                            </span>
                        </p>
                        <p class="text-darkgray text-xs">
                            Seat:
                            <span class="text-secondary text-sm font-medium">
                                {{seat.tdc_seat_number}}
                            </span>
                        </p>
                    </div>
                </div>
            </ng-container>
        </ng-container>
       
        <!-- MEMBERSHIP Buyer -->
        <ng-container *ngIf="membershipBuyerType">
            <div class="mt-2 mb-1 flex justify-between">
                <p class="text-secondary font-medium">
                    {{membershipBuyerType.price_scale}}
                </p>
            </div>
            <div class="flex w-full">
                <p class="text-darkgray">
                    Description:
                    <span class="block text-xs w-3/4">
                        {{membershipBuyerType.buyer_type}}
                    </span>
                </p>
                <p class="text-darkgray"> 
                    Price:
                    <span class="text-secondary">
                        {{ membershipBuyerType.price | currency:currency }}
                    </span>
                </p>

            </div>
        </ng-container>
        
   </ng-container>

</div>